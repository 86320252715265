.lp_radio_button {
  input[type='radio'] {
    ~ label {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
