.sitewide-footer-formatter {
  &__trustmark {
    display: none;
    &-img {
      width: 150px;
    }
    &-wrapper {
      &.trustmark-logo-wrapper {
        display: flex;
        @include swap_direction(margin, 40px auto 0 100px);
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 0 0 auto);
          order: 4;
        }
      }
    }
  }
}
