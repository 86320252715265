.gnav-account {
  &__content-container-forms {
    &__register-container-form {
      .selectbox {
        width: 32.5%;
        border-radius: 0;
        margin: 0 0 20px;
        &.error {
          border: 1px solid $color-error;
        }
      }
    }
  }
}
