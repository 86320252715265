.page-our-stories {
  .blog-landing-page {
    &__title {
      margin: 0 0 30px;
    }
    &__menu {
      display: none;
    }
  }
}
