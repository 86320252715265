@font-face {
  font-family: 'Noto Serif TC';
  src: local('Noto Serif TC Regular'), local('Noto-Serif-TC-Regular'),
    url('/_fonts/jomalone2/cjk/Noto_Serif_TC/NotoSerifTC-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans TC';
  src: local('Noto Sans TC Regular'), local('Noto-Sans-TC-Regular'),
    url('/_fonts/jomalone2/cjk/Noto_Sans_TC/NotoSansTC-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
